import React from "react"

import CelestarScreenshot from "../assets/celestar_screenshot.webp";
import ServiceScanning from "../assets/service_scanning.webp";
import ServiceWalkthrough from "../assets/service_walkthrough.webp";
import ServiceTour from "../assets/service_360.webp";

import PageMetaDefault from "../components/page-meta-default";
import Footer from "../components/footer";
import Header from "../components/header";
import LandingBannerImage from '../assets/landing_illustration.svg';
import {Link} from "gatsby";
import { PAGES } from "../constants"

const Home = () => (
  (
    <>
      <PageMetaDefault />
      <Header />
      <div id="banner-bg">
        <div className="width-limit">
          <div id="landing-banner">
            <h1 id="landing-banner-heading">We build Immersive web experiences</h1>
            <p id="landing-banner-text">
              We strategize, design and develop VR applications across industries from Interior design to Robotics.<br />
                If you want to use VR in your workplace, get in touch with us. We'll make your vision a reality!
            </p>
            <Link to={PAGES.CONTACT_US} className="landing-cta">
              Get in touch
            </Link>
            <img id="landing-banner-illustration" src={LandingBannerImage} alt="" />
          </div>
        </div>
      </div>
      <div className="section">
        <div className="width-limit">
          <h2>Products</h2>
          <div className="flex celestar-flex">
            <div className="celestar-text">
              <h3>Celestar</h3>
              <p>Increase sales and engage customers with our end-to-end 3D and AR platform for e-commerce</p>
              <a href="https://celestar.euclidxr.com" className="outline-button">Find out more</a>
            </div>
            <img src={CelestarScreenshot} className="celestar-screenshot" alt="" />
          </div>
        </div>
      </div>
      <div className="section grey-bg" id="services-section">
        <div className="width-limit">
          <h2>Services</h2>
          <div className="flex section-flex">
            <div className="service-item">
              <p>3D scanning</p>
              <img src={ServiceScanning} alt="" />
            </div>
            <div className="service-item">
              <p>VR walkthrough</p>
              <img src={ServiceWalkthrough} alt="" />
            </div>
            <div className="service-item">
              <p>360 photo based VR</p>
              <img src={ServiceTour} alt="" />
            </div>
          </div>
          <Link to="/services" className="outline-button">Read more</Link>
        </div>
      </div>
      <Footer />
    </>
  )
)

export default Home
